'use client';

import { IndependentSearchForm } from '@/sections/search/IndependentSearchForm.client';
import { usePathname } from 'next/navigation';
import { ComponentPropsWithoutRef } from 'react';

export const NotFoundSearchFormClient = (
	props: Omit<
		ComponentPropsWithoutRef<typeof IndependentSearchForm>,
		'defaultValue'
	>,
) => {
	const pathname = usePathname();
	const defaultValue = pathname?.replace(/[\/-]/g, ' ').trim() || '';

	return <IndependentSearchForm {...props} defaultValue={defaultValue} />;
};
